/* First, import Roboto font from Google Fonts - Add this at the top of your styles.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Global Styles */
body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f5f7fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Ensure all text elements use Roboto with appropriate weights */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-top: 0;
  color: #2d3748;
}

p, span, div, button, input, select, textarea {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #4a5568;
}

/* Navigation and buttons */
.nav-link,
.header-buttons button {
  font-weight: 500;
}

/* Modal styles */
.modal-title {
  font-weight: 700;
}

.modal-content {
  font-weight: 400;
}

/* Pagination */
.pagination-button {
  font-weight: 500;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header Styles */
.header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 10px 0;
}


.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.button-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Add gap between buttons within a group */
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 20px; /* Add gap between button groups */
}


.logo {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.mobile-logo {
  display: none;
}

.search-container {
  flex-grow: 1;
  max-width: 500px;
  margin: 0 20px;
}

.header-center {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.header-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-nav {
  display: flex;
  align-items: center;
}

.nav-link {
  margin-right: 20px;
  color: #333;
  text-decoration: none;
}

.sign-in-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}


/* Footer Styles */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer p {
  margin-bottom: 15px;
}

.footer nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer nav a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
  padding: 5px 0;
}

.footer nav a:hover {
  text-decoration: underline;
}

.mobile-language-toggle {
  display: none;
  padding: 6px 10px;
  font-size: 13px;
  white-space: nowrap;
}

/* ListingCard styles */

/* Ensure consistent box-sizing across all elements */
*, *::before, *::after {
  box-sizing: border-box;
}

.listing-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-height: 350px;
  padding: 0 !important;
}

.listing-card.hovered {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.listing-image-container {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
  border-radius: 0;
}

.listing-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-count-badge {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: 500;
  transition: all 0.2s ease;
}

.image-count-badge:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: scale(1.05);
}

.listing-details {
  padding: 3px 8px;
}

.listing-price {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.listing-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  flex-wrap: wrap;
}

.listing-address {
  font-size: 0.8rem;
  color: #666;
  margin-top: 3px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-info {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}

/* ListingModal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  overflow: hidden;
}

.modal-content {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 650px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
}

.modal-content h2 {
  margin-bottom: 20px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="number"],
textarea,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

/* Additional Listing Modals */
.image-carousel {
  position: relative;
  margin-bottom: 0;
  flex: 0 0 auto;
  width: 100%;
  min-height: 300px;
  max-height: 350px;
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  padding: 0 !important;
}

.image-carousel img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel-button.prev { left: 10px; }
.carousel-button.next { right: 10px; }

.image-counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8em;
}

.listing-details h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 6px;
  margin-top: 6px;
}

.price {
  font-size: 1.5em;
  font-weight: bold;
  /* color: #FF0000; */
  margin-bottom: 10px;
}

.price-per-unit {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.875rem;
  color: #666;
  margin-top: 2px;
  margin-bottom: 6px;
}

.listing-modal .price-per-unit {
  background-color: #f3f4f6;
  padding: 4px 8px;
  border-radius: 4px;
}

.address {
  color: #666;
  margin-bottom: 15px;
}

.property-features {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.property-features span {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
}

.description {
  margin-bottom: 20px;
  line-height: 1.6;
}

.additional-details {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.additional-details p {
  margin: 5px 0;
  font-size: 0.9em;
}

.additional-details strong {
  font-weight: 600;
}

/* Modal Card */


/* MapView Styles */
.map-container {
  height: 400px;
  width: 100%;
}

/* Home Page Styles */
.home {
  text-align: center;
  padding: 50px 0;
}

.home h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.home p {
  font-size: 18px;
  margin-bottom: 30px;
}

.home .cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 18px;
}

.home-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Home Card */
.card-container {
  margin-top: 2rem;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  width: 100%;
  padding: 2rem 0;
  padding-top: 20px; /* Add space above the cards for the dots */
}

.card-wrapper {
  padding: 0 10px; /* Adjust horizontal padding between cards */
}

.card {
  width: 100%;
  height: 100%; /* Ensure all cards have the same height */
  max-width: 300px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: white;
  display: flex;
  flex-direction: column;
}

.card-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


.card-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.card-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.card-text {
  color: #6c757d;
  margin-bottom: 20px;
  flex-grow: 1;
  width: 100%;
}

.cta-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.cta-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3;
}

.home-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../public/images/hero_image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  margin-bottom: 10px; /* Add some space below the hero */
}

.home-hero * {
  color: white !important;
}

.home-hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: white;
}

.home-hero p {
  font-size: 1.5rem;
  max-width: 600px;
  color: white;
}

.home-page .static-page-content-wrapper {
  flex-grow: 1;
  width: 100%;
  max-width: none;
  padding: 0;
  margin: 0;
}
/* Slick slider custom styles */
.slick-slider {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide > div {
  padding: 0 10px;
}

.custom-arrow {
  position: absolute;
  top: 100px; /* Adjust this value to align with the center of your card images */
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
}

.slick-prev {
  left: -20px;
}

.slick-next {
  right: -20px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-slide {
  transition: all 0.3s ease;
}

.slick-current {
  transform: scale(1.05);
}

/* Dot styles */
.slick-dots {
  position: absolute;
  top: -25px; /* Adjust this value as needed */
  bottom: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  content: '•';
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  opacity: 0.25;
  color: black;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}



/* SearchBar Styles */
.search-bar {
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.search-input {
  flex-grow: 1;
  padding: 12px 16px;
  font-size: 16px;
  border: none;
  outline: none;
}
/* .search-bar input::placeholder {
  text-align: left;
} */

.search-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.search-button:hover {
  background-color: #0056b3;
}

.search-button-icon {
  width: 24px;
  height: 24px;
  color: white;
}


/* Search Page */
.search-page {
  display: grid;
  height: calc(100vh - 60px);
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "filters filters" "listings map";
  transition: all 0.3s ease-in-out;
}

.search-page.map-hidden {
  grid-template-columns: 1fr 0;
}

.filter-toggle-btn {
  display: none; /* Hidden by default on desktop */
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.filters-container {
  padding: 1rem;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e0e0e0;
  grid-area: filters;
}

.filters-container.show {
  display: block;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filters select, 
.filters button {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
}


.filters button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filters button:hover {
  background-color: #0056b3;
}

.search-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/* Existing styles ... */

.filter-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.apply-filters-btn,
.clear-filters-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.apply-filters-btn {
  background-color: #007bff;
  color: white;
  white-space: nowrap;
}

.apply-filters-btn:hover {
  background-color: #0056b3;
}

.clear-filters-btn {
  background-color: #dc3545 !important;
  color: white !important;
  white-space: nowrap;
}

.clear-filters-btn:hover {
  background-color: #c82333;
}

.admin-bg-green {
  background-color: #10b981 !important;
  color: white !important;
}

.admin-bg-green:hover {
  background-color: #059669 !important;
}

/* filter component (later added) */

.filter-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.filter-row {
  display: flex;
  gap: 10px;
}

.filter-item {
  flex: 1;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}

/* User Filter Styles */
.user-filter {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 16px;
}

.filter-label {
  margin-left: 10px;
  font-size: 14px;
}

/* Filter neighborhoods */

/* In styles.css */
.neighborhood-filter {
  flex: 1;
  min-width: 200px;
}

.basic-multi-select {
  color: #333;
}

/* React-select custom styles */
.select__control {
  border-radius: 4px !important;
  border: 1px solid #e5e7eb !important;
  min-height: 40px !important;
  min-width: 180px !important; /* Ensure minimum width */
}

.select__menu {
  z-index: 100 !important;
  width: auto !important;
  min-width: 100% !important; /* Ensure menu is at least as wide as control */
}

.select__option {
  padding: 8px 12px !important;
}

.select__multi-value {
  background-color: #e5e7eb !important;
  border-radius: 4px !important;
  margin: 2px !important;
}

.select__multi-value__label {
  font-size: 0.875rem !important;
  padding: 2px 6px !important;
}

.select__multi-value__remove {
  padding: 0 4px !important;
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

input:checked + .slider {
  background-color: #4CAF50;
}

input:checked + .slider:before {
  transform: translateX(22px);
}

/* Update listings-column to handle pagination */
.listings-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-area: listings;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  overflow: hidden;
}

.listings-grid {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

/* Mobile pagination is inside listings column, desktop is outside */
.mobile-pagination {
  display: none; /* Hidden on desktop */
}

.desktop-pagination {
  display: flex; /* Shown on desktop */
}

/* Pagination Styles */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px; /* Reduced from 0.5rem */
  padding: 0.5rem;
  margin-top: auto;
  background-color: white;
  border-top: 1px solid #e5e7eb;
  flex-wrap: wrap; /* Allow buttons to wrap on small screens */
  width: 100%;
  grid-column: 1 / -1; /* Make pagination span all columns */
}

.pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; /* Reduced from 2.5rem */
  height: 32px; /* Reduced from 2.5rem */
  min-width: 32px; /* Add min-width */
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background-color: white;
  color: #374151;
  font-size: 0.813rem; /* Slightly smaller font */
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0; /* Remove padding */
}

.pagination-button:hover:not(:disabled) {
  background-color: #f3f4f6;
  border-color: #d1d5db;
}

.pagination-button.active {
  background-color: #3b82f6;
  border-color: #3b82f6;
  color: white;
}

.pagination-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-button.dots {
  border: none;
  background: none;
  cursor: default;
}

.pagination-button.dots:hover {
  background: none;
}

/* MapView styles */
.map-column {
  grid-area: map;
  height: 100%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  transform-origin: right center;
}

.map-container {
  height: 100%;
  width: 100%;
}

.map-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #666;
  font-size: 1.2rem;
}


/* Language switcher */

.language-switcher {
  margin-left: 15px;
  margin-right: 5px;
}

.language-switcher button {
  background-color: transparent;
  border: 1px solid #ddd;
  padding: 5px 10px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 14px;
}

.language-switcher button:hover {
  background-color: #e0e0e0;
}

.language-toggle {
  background-color: #c0c8d1;
  color: #333;
  border: 1px solid #02070b;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.language-toggle:hover {
  background-color: #e2e6ea;
}

/* Posts */
.post-button, .auth-button, .language-toggle {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.post-button {
  background-color: #007bff;
  color: white;
}

.post-button:hover {
  background-color: #f0f0f0;
}

.post-button .button-icon {
  margin-right: 5px;
}

.auth-button {
  background-color: #28a745;
  color: white;
}

.auth-button:hover {
  background-color: #0056b3;
}

.auth-button.signed-in {
  background-color: #dc3545;
}

.auth-button.signed-in:hover {
  background-color: #dc3545;
}

.mobile-post-button {
  display: none;
  font-size: 24px;
  padding: 4px 12px;
}

/* Existing styles... */

.location-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.location-button {
  flex: 1;
  padding: 0.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.location-button:hover {
  background-color: #0056b3;
}

.coordinates-display {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.coordinates-display {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.coordinates-display p {
  margin: 0.25rem 0;
}

.map-fallback {
  width: 100%;
  height: 200px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.map-fallback p {
  margin: 0.25rem 0;
}

/* New styles for map preview */
.coordinates-display div {
  margin-top: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  overflow: hidden;
}


/* Auth Modal specific styles */
.auth-tabs {
  display: flex;
  margin-bottom: 20px;
}

.auth-tab {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.auth-tab.active {
  background-color: #007bff;
  color: white;
}

/* Form styles */
form {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

input, textarea {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}


/* image previews for uploads */
.image-preview-item {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 4px;
}

.image-preview-item {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 4px;
}

.image-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.image-name {
  flex-grow: 1;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-image {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.remove-image:hover {
  background-color: #ff7875;
}

/* Submit */

.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* .close-button {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 15px;
} */

.close-button:hover {
  background-color: #e0e0e0;
}

/* No listings message */
.loading-message,
.no-listings-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
  max-height: 70px;
}

.loading-message {
  font-style: italic;
}

.no-listings-message {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Auto complete */

.autocomplete {
  position: relative;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items li {
  padding: 10px;
  cursor: pointer;
  background-color: #fff; 
  border-bottom: 1px solid #d4d4d4; 
}

.autocomplete-items li:hover {
  background-color: #e9e9e9; 
}

/* Listing Modal */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.post-button, 
.edit-button, 
.auth-button, 
.delete-button,
.close-button,
.language-toggle,
.admin-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.edit-button {
  background-color: #6c757d;
  color: white;
}

.edit-button:hover {
  color: #c82333;
  background-color: #efda19;
}

.admin-button {
  background-color: #9333ea;
  color: white;
}

.admin-button:hover {
  background-color: #7e22ce;
}

.delete-button {
  color: #ffff00;
  background-color: #e56611;
}

.delete-button:hover {
  color: #ffff00;
  background-color: #f40b0b;
}


/* Static Pages (About, Contact, Terms, Privacy) Styles */
.static-page {
  font-family: Arial, sans-serif;
}

.static-page-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../public/images/hero_image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.static-page-hero h1 {
  color: white;
  font-size: 2.5rem;
  text-align: center;
}

.static-page-content-wrapper {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.static-page-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.static-page-card h2 {
  color: #006aff;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.static-page-card p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

.static-page-card a {
  color: #006aff;
  text-decoration: none;
}

.static-page-card a:hover {
  text-decoration: underline;
}

.static-page-card address {
  font-style: normal;
  line-height: 1.6;
}

.static-page-card ul {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.static-page-card li {
  margin-bottom: 0.5rem;
}

.static-page-cta {
  display: inline-block;
  background-color: #006aff;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.static-page-cta:hover {
  background-color: #0056b3;
  text-decoration: none;
}


/* Modal header css */

.modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid #f1f5f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 60px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #1e293b;
  padding-right: 40px;
  word-break: break-word;
  max-width: calc(100% - 40px);
}

.modal-close-icon {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #64748b;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  min-width: 32px;
  min-height: 32px;
  z-index: 1;
}

.modal-close-icon:hover {
  background-color: #f1f5f9;
  color: #1e293b;
}

.modal-content {
  width: 90%; /* Adjust width for mobile */
  max-height: 90vh;
  overflow-y: auto;
  margin: 20px;
  background: white;
  border-radius: 8px;
  padding: 0; /* Remove default padding */
}

.modal-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Toast Styles */
.toast-notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  max-width: 450px;
  background-color: white;
  color: #1e293b;
  padding: 16px 20px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: flex-start;
  z-index: 1000;
  animation: fadeIn 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  border-left: 5px solid;
  backdrop-filter: blur(10px);
}

.toast-notification::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.toast-notification[data-type="error"] {
  border-left-color: #ef4444;
  background-color: #fff1f2;
}

.toast-notification[data-type="success"] {
  border-left-color: #10b981;
  background-color: #ecfdf5;
}

.toast-notification[data-type="warning"] {
  border-left-color: #f59e0b;
  background-color: #fffbeb;
}

.toast-notification[data-type="error"]::before {
  content: "⚠️";
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 20px;
  line-height: 1;
}

.toast-notification[data-type="success"]::before {
  content: "✓";
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  color: #10b981;
}

.toast-notification[data-type="warning"]::before {
  content: "⚠️";
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 20px;
  line-height: 1;
}

.toast-message {
  flex: 1;
  margin-left: 36px;
  margin-right: 24px;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 500;
}

.toast-message-item {
  margin-bottom: 8px;
  position: relative;
  padding-left: 16px;
}

.toast-message-item:last-child {
  margin-bottom: 0;
}

.toast-message-item::before {
  content: "•";
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
  color: #6366f1;
}

/* For single message toasts, remove the bullet point */
.toast-message-item:only-child {
  padding-left: 0;
}

.toast-message-item:only-child::before {
  display: none;
}

.toast-close-button {
  background: rgba(0, 0, 0, 0.05);
  border: none;
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: #64748b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: bold;
  margin-top: -4px;
}

.toast-close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #334155;
  transform: scale(1.1);
}

/* Updated animations for centered toast */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -54%);
    filter: blur(2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
    filter: blur(0);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.05);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%);
    filter: blur(0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -54%);
    filter: blur(2px);
  }
}

.toast-exit {
  animation: fadeOut 0.3s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

/* Optional overlay background */
.toast-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 23, 42, 0.3);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  animation: fadeBackground 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes fadeBackground {
  from {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  to {
    opacity: 1;
    backdrop-filter: blur(2px);
  }
}

/* Mobile Styles */
@media (max-width: 768px) {

  .search-page {
    grid-template-areas:
      "map-toggle-btn"
      "map"
      "filter-toggle-btn"
      "filters"
      "listings"
      "pagination";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto 1fr auto;
  }
  
  /* Show mobile toggle buttons on mobile only */
  .map-toggle-btn,
  .filter-toggle-btn {
    display: flex !important;
  }
  
  .pagination-container {
    grid-area: pagination;
    width: 100%;
    padding: 8px 0;
    background-color: white;
    position: static; /* Change from fixed to static for mobile */
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    /* Make pagination always visible */
    display: block !important;
    visibility: visible !important;
    margin-top: auto; /* Push to bottom of listings-column */
  }
  
  /* Initially hide map on mobile, but keep it visible on desktop */
  .map-column {
    display: none;
  }
  
  .map-column.show {
    display: block;
    height: 30vh;
    animation: slideDown 0.3s ease-in-out;
  }
  
  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 30vh;
      opacity: 1;
    }
  }
  
  /* Fix modal responsiveness on mobile */
  .modal-overlay {
    padding: 0;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
  }
  
  .modal-content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100vh;
    border-radius: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .modal-header, .admin-modal-header {
    padding: 12px 16px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
  }
  
  .admin-modal-title {
    font-size: 16px;
    max-width: calc(100% - 45px);
    line-height: 1.3;
  }
  
  .admin-modal-title .title-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    word-break: break-word;
  }
  
  .modal-body {
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Reduce font size on small screens */
  .modal-body input[type="text"],
  .modal-body input[type="number"],
  .modal-body input[type="file"],
  .modal-body select,
  .modal-body textarea {
    font-size: 16px; /* Prevents iOS zoom on focus */
    padding: 8px 10px;
    margin-bottom: 12px;
  }
  
  .location-buttons {
    flex-direction: column;
    gap: 8px;
  }
  
  .image-previews {
    justify-content: center;
  }
  
  .image-preview-item {
    width: calc(50% - 8px); /* Two images per row, accounting for gap */
    max-width: 150px;
    box-sizing: border-box;
  }
  
  .image-thumbnail {
    height: 80px; /* Smaller height on mobile */
  }
  
  .submit-button, .close-button {
    padding: 10px 20px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .search-bar {
    max-width: none;
  }

  .search-input {
    font-size: 14px;
  }

  .search-button-icon {
    width: 20px;
    height: 20px;
  }

  .card-container {
    padding-top: 10px; /* Add space above the cards for the dots */
  }

  .card {
    width: 90%;
    max-width: 300px;
    margin-bottom: 20px;
  }

  .header-center {
    order: 2;
    width: 100%;
    margin: 10px 0;
  }

  .header-content {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

/* Page containers for converted modal pages */
.page-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0;
  min-height: calc(100vh - 200px); /* Account for header and footer */
  background-color: transparent;
  position: relative;
}

.page-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(180deg, rgba(79, 70, 229, 0.03) 0%, rgba(79, 70, 229, 0) 100%);
  z-index: -1;
}

.page-container.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  color: #4a5568;
  background-color: #fff;
  border-radius: 12px;
  padding: 4rem 2rem;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.page-container.loading::before {
  display: none;
}

.page-container.loading::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #4f46e5;
  animation: spin 1s linear infinite;
  margin-left: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e2e8f0;
  padding: 1.5rem 1.5rem 1.5rem;
  background: linear-gradient(135deg, #f8f9ff 0%, #f1f5f9 100%);
  border-radius: 12px 12px 0 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
  position: relative;
  overflow: hidden;
}

.page-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, #4f46e5, #8b5cf6);
}

.page-header h1 {
  margin: 0;
  font-size: 1.75rem;
  color: #1e293b;
  font-weight: 600;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-bottom: 0.75rem;
}

/* Add the blue dot marker for consistency with h2 */
.page-header h1::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #4f46e5;
  border-radius: 50%;
  margin-right: 12px;
}

.page-header h1::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, #4f46e5, transparent);
  position: absolute;
  bottom: -3px;
  left: 0;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.page-header:hover h1::after {
  width: 80px;
}

.page-header .back-button {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  color: #334155;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.page-header .back-button:hover {
  background-color: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.page-header .back-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  /* Container adjustments */
  .page-container {
    padding: 1rem 0;
    box-sizing: border-box;
  }
  
  /* Main page container standardization */
  .edit-post-page,
  .create-post-page,
  .edit-profile-page {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    box-sizing: border-box;
    padding: 0 0.5rem;
  }
  
  /* Form container reset to ensure proper width */
  .edit-post-form,
  .create-post-form,
  .edit-profile-form {
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Content wrapper standardization */
  .edit-post-content,
  .create-post-content,
  .edit-profile-content {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Header card with standard width */
  .page-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.25rem 1rem;
    border-radius: 8px;
    margin: 0 0 1rem 0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }
  
  .page-header h1 {
    font-size: 1.5rem;
  }
  
  .page-header h1::before {
    width: 8px;
    height: 8px;
    margin-right: 8px;
  }
  
  .page-header h1::after {
    width: 40px;
    height: 2px;
  }
  
  .page-header .back-button {
    align-self: flex-start;
  }
  
  /* Form sections with same width as header */
  .form-section {
    padding: 1.5rem 1rem;
    margin: 0 0 1rem 0;
    width: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  }
  
  .form-section h2 {
    font-size: 1.25rem;
  }
  
  .form-section h2::before {
    width: 7px;
    height: 7px;
  }
  
  .form-section h2::after {
    width: 35px;
    height: 2px;
  }
  
  /* Form gap adjustment for mobile */
  .edit-post-form,
  .create-post-form,
  .edit-profile-form {
    gap: 0;  /* Removing gap since we're using margin on children */
  }
}

/* Edit Post Page Styles */
.edit-post-page,
.create-post-page,
.edit-profile-page {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  max-width: 1200px;
  margin: 2rem auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  width: 100%;
}

.edit-post-content,
.create-post-content,
.edit-profile-content {
  padding: 2rem;
}

.edit-post-form,
.create-post-form,
.edit-profile-form {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.form-section {
  background: linear-gradient(to right, #ffffff, #f8fafc);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
  /* Removed the left border to standardize with horizontal underlines */
  transition: all 0.3s ease;
  position: relative;
  margin-bottom: 2rem;
}

.form-section:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  transform: translateY(-2px);
}

.form-section h2 {
  margin-top: 0;
  margin-bottom: 1.75rem;
  font-size: 1.4rem;
  color: #1e293b;
  /* Removed border-bottom as we'll use the ::after element for styling */
  padding-bottom: 0.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  letter-spacing: 0.02em;
  position: relative;
}

/* Keep the blue dot marker */
.form-section h2::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: #4f46e5;
  border-radius: 50%;
}

/* Add the horizontal blue underline similar to h1 styling */
.form-section h2::after {
  content: "";
  display: block;
  width: 40px;
  height: 3px;
  background: linear-gradient(to right, #4f46e5, transparent);
  position: absolute;
  bottom: -5px;
  left: 0;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.form-section:hover h2::after {
  width: 60px;
}

/* Form subsection styling */
.form-subsection {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  position: relative;
}

.form-subsection:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .form-subsection {
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
  }
}

.form-subsection h3 {
  font-size: 1.1rem;
  color: #475569;
  margin-bottom: 1.25rem;
  padding-bottom: 0.5rem;
  /* Removed dashed border-bottom as we'll use the ::after element for styling */
  text-transform: capitalize;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  position: relative;
}

.form-subsection h3::before {
  content: "•";
  color: #4f46e5;
  margin-right: 8px;
  font-size: 1.4em;
  line-height: 0;
}

/* Add a smaller horizontal underline to maintain consistency */
.form-subsection h3::after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background: linear-gradient(to right, #4f46e5, transparent);
  position: absolute;
  bottom: -3px;
  left: 0;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.form-subsection:hover h3::after {
  width: 45px;
}

/* Improved field styling */
.field-with-icon {
  position: relative;
}

.field-with-icon input,
.field-with-icon select {
  padding-left: 2.5rem;
}

.field-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #64748b;
  font-size: 1.1rem;
}

/* Field description helper text */
.field-description {
  font-size: 0.8rem;
  color: #64748b;
  margin-top: 0.5rem;
  font-style: italic;
}

.form-group {
  margin-bottom: 1.75rem;
  position: relative;
}

.form-group:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .form-group {
    margin-bottom: 1.25rem;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Ensure form inputs take full width */
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    box-sizing: border-box;
    max-width: none;
  }
  
  /* Improve appearance of inputs */
  .form-group input[type="text"],
  .form-group input[type="number"],
  .form-group input[type="email"],
  .form-group input[type="password"],
  .form-group input[type="tel"],
  .form-group select {
    font-size: 16px; /* Prevents zoom on iOS */
  }
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #334155;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  text-transform: capitalize;
  letter-spacing: 0.01em;
}

.form-group label.field-label {
  font-size: 1rem;
  color: #1e293b;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
}

.form-group label.field-label::after {
  content: "";
  display: block;
  flex: 1;
  height: 1px;
  background-color: #e2e8f0;
  margin-left: 10px;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group input[type="tel"],
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 0.9rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #fff;
  color: #1e293b;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
}

.form-group input[type="file"] {
  width: 100%;
  padding: 0.9rem 1rem;
  border: 2px dashed #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background-color: #f8fafc;
  color: #1e293b;
  cursor: pointer;
  display: block;
  margin-bottom: 0.5rem;
  position: relative;
}

.form-group input[type="file"]::file-selector-button {
  background: linear-gradient(to right, #4f46e5, #6366f1);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.form-group input[type="file"]::file-selector-button:hover {
  background: linear-gradient(to right, #4338ca, #4f46e5);
}

.form-group input[type="file"]:hover {
  border-color: #4f46e5;
  background-color: rgba(79, 70, 229, 0.03);
}

.form-group input::placeholder,
.form-group textarea::placeholder,
.form-group select::placeholder {
  color: #94a3b8;
}

.form-group input:hover,
.form-group textarea:hover,
.form-group select:hover {
  border-color: #cbd5e1;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: #4f46e5;
  outline: none;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.15);
}

.form-group input.readonly-field {
  background-color: #f8fafc;
  border-color: #e2e8f0;
  color: #64748b;
  cursor: not-allowed;
}

.form-group input.readonly-field:focus {
  box-shadow: none;
  border-color: #e2e8f0;
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.radio-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #e2e8f0;
}

.radio-group label:hover {
  background-color: rgba(228, 228, 254, 0.15);
  border-color: #d8b4fe;
}

.radio-group input[type="radio"]:checked + span {
  color: #4f46e5;
  font-weight: 500;
}

.radio-group input[type="radio"] {
  accent-color: #4f46e5;
  transform: scale(1.2);
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  font-weight: 500;
  color: #334155;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background-color: rgba(249, 250, 251, 0.8);
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
  width: fit-content;
}

.checkbox-group label:hover {
  background-color: rgba(228, 228, 254, 0.15);
  border-color: #d8b4fe;
}

.checkbox-group input[type="checkbox"] {
  accent-color: #4f46e5;
  width: 18px;
  height: 18px;
}

.coordinates-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #f8fafc;
  padding: 1.25rem;
  border-radius: 8px;
  border: 1px dashed #cbd5e1;
}

.coordinate-help-text {
  margin-top: 0.75rem;
  font-size: 0.8rem;
  color: #64748b;
  font-style: italic;
  text-align: center;
  padding: 0.5rem;
  background-color: rgba(203, 213, 225, 0.2);
  border-radius: 6px;
}

.coordinate-inputs {
  display: flex;
  gap: 1rem;
}

.coordinate-inputs > div {
  flex: 1;
}

.location-button {
  padding: 0.9rem 1.2rem;
  background: linear-gradient(to right, #4f46e5, #6366f1);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.location-button:hover {
  background: linear-gradient(to right, #4338ca, #4f46e5);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(79, 70, 229, 0.25);
}

.location-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(79, 70, 229, 0.2);
}

.location-button:disabled {
  background: linear-gradient(to right, #9ca3af, #cbd5e1);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

.location-button svg {
  width: 18px;
  height: 18px;
}

.neighborhood-group {
  position: relative;
}

.input-help-text {
  font-size: 0.75rem;
  color: #64748b;
  font-weight: normal;
  margin-left: 0.5rem;
  font-style: italic;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input-wrapper input {
  padding-right: 35px; /* Make room for the search icon */
}

.search-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #64748b;
  pointer-events: none; /* Make sure clicks pass through to the input */
}

.neighborhood-suggestions {
  position: absolute;
  top: 100%; /* Position below the input field */
  left: 0;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 0 0 8px 8px; /* Round only bottom corners */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  z-index: 20;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
  margin-top: 2px; /* Small gap between input and dropdown */
}

.neighborhood-suggestions::before {
  content: "Selecting a neighborhood will update the coordinates";
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-style: italic;
  color: #6366f1;
  background-color: rgba(99, 102, 241, 0.05);
  border-bottom: 1px solid #e2e8f0;
  text-align: center;
}

.neighborhood-suggestions::-webkit-scrollbar {
  width: 6px;
}

.neighborhood-suggestions::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.neighborhood-suggestions::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 6px;
}

.neighborhood-option {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid #f1f5f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.neighborhood-option::after {
  content: "📍";
  font-size: 0.8rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.neighborhood-option:hover::after {
  opacity: 1;
}

.neighborhood-option:last-child {
  border-bottom: none;
}

.neighborhood-option:hover {
  background-color: #f8fafc;
  color: #4f46e5;
}

.no-matches-message {
  padding: 1rem;
  text-align: center;
  color: #64748b;
  font-size: 0.9rem;
  font-style: italic;
  background-color: #f8fafc;
}

.image-preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 1rem;
  margin-top: 1.25rem;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .image-preview-container {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    width: 100%;
    margin-top: 1rem;
    gap: 0.75rem;
  }
}

.image-preview {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border: 2px solid #fff;
  transition: all 0.3s ease;
}

.image-preview:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.image-preview:hover img {
  transform: scale(1.1);
}

.remove-image {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.85);
  color: #ef4444;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  font-size: 1.2rem;
  font-weight: bold;
}

.remove-image:hover {
  background-color: #fff;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1.25rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e2e8f0;
}

@media (max-width: 768px) {
  .form-actions {
    margin: 0 0 1rem 0;
    width: 100%;
    padding: 1.25rem 1rem;
    background-color: #f8fafc;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
    justify-content: space-between;
    box-sizing: border-box;
  }
}

@media (max-width: 480px) {
  .form-actions {
    flex-direction: column-reverse;
    gap: 0.75rem;
  }
  
  .form-actions button {
    width: 100%;
  }
}

.cancel-button {
  background-color: #f1f5f9;
  color: #475569;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  padding: 0.9rem 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-button:hover {
  background-color: #e2e8f0;
  color: #334155;
}

.cancel-button:active {
  transform: translateY(1px);
}

/* Phone input styling */
.phone-input {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.phone-input:hover {
  border-color: #cbd5e1;
}

.phone-input:focus-within {
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.15);
}

.phone-input input {
  border: none !important;
  box-shadow: none !important;
  margin: 0 !important;
}

.phone-input input:focus {
  box-shadow: none !important;
}

.phone-input .country-code {
  background-color: #f8fafc;
  padding: 0.9rem 0.75rem;
  border-right: 1px solid #e2e8f0 !important;
  font-weight: 500;
  color: #64748b;
  width: 5rem;
  text-align: center;
}

.phone-input input[type="tel"] {
  flex: 1;
}

.submit-button {
  padding: 0.9rem 1.75rem;
  background: linear-gradient(to right, #4f46e5, #6366f1);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.submit-button:hover {
  background: linear-gradient(to right, #4338ca, #4f46e5);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(79, 70, 229, 0.25);
}

.submit-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(79, 70, 229, 0.2);
}

/* Responsive styles for page forms */
@media (max-width: 768px) {
  .page-container {
    padding: 1rem;
  }
  
  .coordinate-inputs {
    flex-direction: column;
  }
  
  .form-actions {
    flex-direction: column;
  }
  
  .form-actions button {
    width: 100%;
  }
  
  .cancel-button {
    order: 2;
  }
  
  .submit-button {
    order: 1;
    margin-bottom: 0.5rem;
  }
}

  .header-center {
    order: 3;
    width: 100%;
    margin: 10px 0;
  }

  .header-nav {
    display: none;
  }

  .nav-link, .sign-in-button {
    display: none;
  }

  /* logo */
  .logo-container {
    flex: 0 0 100%;
    text-align: center;
    order: 1;
    margin-bottom: 10px;
  }

  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: inline;
  }

  .search-container {
    order: 1;
    width: 100%;
    margin: 10px 0;
  }

  .header-content {
    flex-wrap: wrap;
    padding: 0 8px; /* Add padding to prevent buttons from touching the edge */
  }

  .header-buttons {
    width: 100%;
    justify-content: space-between;
    order: 2;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0 8px;
  }

  .post-button, .auth-button, .language-toggle {
    margin: 0;
    padding: 8px;
    font-size: 13px;
    flex: 0 1 auto;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .post-button .button-text {
    display: none;
  }

  .post-button .button-icon {
    margin-right: 0;
  }
  
  /* Make auth buttons smaller and more compact */
  .auth-button {
    padding: 6px 8px;
    font-size: 12px;
    flex-shrink: 0;
  }

  /* Fix listings column layout */
  .listings-column {
    grid-area: listings;
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }

  .listings-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
    gap: 10px;
    padding: 10px;
    height: auto;
  }

  /* Adjust listing grid spacing */
  .listings-grid {
    padding-bottom: 10px; /* Reduce bottom padding since pagination is now part of the column */
  }
  
  /* Mobile pagination styling - show mobile pagination and hide desktop pagination */
  .mobile-pagination {
    display: block !important;
    width: 100%;
    padding: 10px 0;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    margin-top: 15px;
  }
  
  .desktop-pagination {
    display: none !important;
  }
  
  .pagination {
    font-size: 0.9rem;
    padding: 5px 0;
  }
  
  /* Adjust card styles */
  .listing-card {
    width: 100%; /* Take full width of grid cell */
    margin: 0; /* Remove margins since we're using grid gap */
    height: auto;
    min-height: 320px;
    /* max-height: 350px; */
  }

  /* Adjust font sizes for better fit on mobile */
  .listing-card h3 {
    font-size: 0.9rem;
  }

  .listing-info {
    font-size: 0.75rem;
  }

  .listing-address {
    font-size: 0.7rem;
  }

  .map-column {
    grid-area: map;
    height: 30vh; /* Changed from 50vh to 35vh */
  }

  /* MapView styles */
  .map-container {
    height: 100%;
    width: 100%;
  }

  .filter-toggle-btn, .map-toggle-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .filter-toggle-btn {
    grid-area: filter-toggle-btn;
  }
  
  .map-toggle-btn {
    grid-area: map-toggle-btn;
    background-color: #10b981; /* Green color to differentiate from filter button */
  }
  
  .toggle-btn-icon,
  .filter-toggle-btn svg,
  .map-toggle-btn svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 4px;
  }

  .filters-container {
    grid-area: filters;
    display: none;
  }

  .filters-container.show {
    display: block;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .filters select, .filters button {
    width: 100%;
  }

  .user-filter {
    width: 100%;
    justify-content: flex-start;
  }

  .neighborhood-filter {
    width: 100%;
    min-width: 180px; /* Ensure enough width for neighborhood selector */
  }

  .slick-dots {
    top: -23px; /* Adjust this value as needed */
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  .language-switcher {
    margin-left: 0;
  }

  .mobile-language-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    padding: 6px 8px;
    font-size: 12px;
    flex-shrink: 0;
  }

  .desktop-language-toggle {
    display: none;
  }
  
  /* Hide desktop map toggle on mobile completely */
  .desktop-map-toggle {
    display: none !important;
  }  

  .desktop-post-button {
    display: none;
  }

  .mobile-post-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    font-size: 12px;
    width: auto;
  }

  /* About, privacy, contact */
  .static-page-hero h1 {
    font-size: 2rem;
  }

  .static-page-card {
    padding: 1.5rem;
  }
  
  /* Home page */
  .home-hero h1 {
    font-size: 2rem;
  }

  .home-hero p {
    font-size: 1.2rem;
  }

  .slick-slider {
    width: 95%; /* Slightly wider on mobile */
  }

  /* Modal header */
  /* Remove redundant mobile styles */

  /* Pagination  */
  .pagination {
    gap: 2px; /* Even smaller gap on mobile */
    padding: 0.25rem; /* Less padding */
    margin: 0;
    border-top: none;
  }

  .pagination-button {
    width: 28px; /* Even smaller on mobile */
    height: 28px; /* Even smaller on mobile */
    min-width: 28px;
    font-size: 0.75rem;
  }

  /* Hide some pagination items on very small screens */
  .pagination-button.dots {
    display: none;
  }

/* Form elements within modals */
.modal-body input[type="text"],
.modal-body input[type="number"],
.modal-body input[type="file"],
.modal-body select,
.modal-body textarea {
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  color: #1e293b;
  transition: all 0.2s;
  margin-bottom: 16px;
  max-width: 100%; /* Ensure inputs don't overflow on mobile */
  box-sizing: border-box; /* Include padding in width calculation */
}

.modal-body input[type="text"]:focus,
.modal-body input[type="number"]:focus,
.modal-body select:focus,
.modal-body textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.modal-body textarea {
  min-height: 100px;
  resize: vertical;
}

/* Admin-styled buttons for modals */
.submit-button {
  background-color: #4f46e5;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  margin-top: 24px;
  transition: all 0.2s;
}

.submit-button:hover {
  background-color: #4338ca;
}

.close-button {
  background-color: #ffffff;
  color: #64748b;
  border: 1px solid #e2e8f0;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  margin-top: 12px;
  transition: all 0.2s;
}

.close-button:hover {
  background-color: #f8fafc;
  color: #1e293b;
}

.edit-button {
  background-color: #10b981;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;
  transition: all 0.2s;
}

.edit-button:hover {
  background-color: #059669;
}

.delete-button {
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.delete-button:hover {
  background-color: #dc2626;
}

/* Image previews */
.image-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
  width: 100%;
  box-sizing: border-box;
}

.image-preview-item {
  width: 150px;
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}

.image-preview-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-thumbnail {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 6px;
}

.image-name {
  display: block;
  font-size: 13px;
  color: #64748b;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  color: #ef4444;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}

.remove-image:hover {
  background-color: #fee2e2;
}

/* Calculated price display */
.calculated-price {
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 16px;
  padding: 10px 12px;
  background-color: #f1f5f9;
  border-radius: 8px;
  border-left: 3px solid #4f46e5;
}

/* Autocomplete styles */
.autocomplete {
  position: relative;
  margin-bottom: 16px;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #e2e8f0;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.autocomplete-items li {
  padding: 10px 12px;
  cursor: pointer;
  border-bottom: 1px solid #f1f5f9;
  font-size: 14px;
  color: #4b5563;
  transition: all 0.2s;
}

.autocomplete-items li:hover {
  background-color: #f8fafc;
  color: #1e293b;
}

/* Location buttons */
.location-buttons {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.location-button {
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.location-button:hover {
  background-color: #f1f5f9;
  border-color: #cbd5e1;
}

.coordinates-display {
  background-color: #f8fafc;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #4b5563;
  border-left: 3px solid #3b82f6;
}

/* Desktop Styles */
@media (min-width: 769px) {
  /* Layout adjustments for desktop */
  .search-page {
    grid-template-areas:
      "filters filters"
      "listings map"
      "pagination pagination";
    grid-template-columns: 70% 30%;
    grid-template-rows: auto 1fr auto;
  }
  
  /* Override mobile map styles for desktop */
  .map-column {
    display: block;
    height: 100%;
    transition: all 0.3s ease;
  }
  
  /* When map is hidden, expand listings */
  .search-page.map-hidden {
    grid-template-columns: 100% 0%;
  }
  
  .search-page.map-hidden .map-column {
    display: none;
  }

  /* Hide mobile toggle buttons on desktop */
  .map-toggle-btn,
  .filter-toggle-btn {
    display: none !important; /* Force hide mobile buttons on desktop */
  }
  
  .desktop-map-toggle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: #10b981;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 0 12px;
    width: auto; /* Auto width based on content */
    font-size: 13px; /* Slightly smaller font to match others */
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap; /* Prevent text wrapping */
    flex: 0 0 auto;
    height: 40px;
    min-width: auto;
    margin-left: 5px;
    order: 3; /* Move to the end, after the Clear Filters button */
  }
  
  .desktop-map-toggle:hover {
    background-color: #059669;
  }
  
  .desktop-map-toggle svg,
  .desktop-map-toggle .admin-btn-icon,
  .desktop-map-toggle .toggle-btn-icon,
  .desktop-map-toggle .toggle-btn-icon svg {
    width: 16px !important;
    height: 16px !important;
    flex-shrink: 0;
    margin-right: 4px;
    display: inline-block;
    overflow: visible;
  }
  
  .pagination-container {
    grid-area: pagination;
    width: 100%;
    padding: 8px 0;
    background-color: white;
    border-top: 1px solid #e5e7eb;
  }

  /* MapView styles */
  .map-container {
    height: 100%;
    width: 100%;
  }

  .map-column {
    grid-area: map;
    overflow: hidden;
    height: 100%; /* Ensure it fills the grid area height */
  }

  .listings-column {
    grid-area: listings;
    overflow-y: auto;
  }

  .listings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }

  /* Adjust listing cards for 4 per row */
  .listing-card {
    width: 100%;
    margin: 0;
    min-height: 330px;
    margin-bottom: 20px;
  }

  .listing-card:nth-child(4n) {
    margin-right: 0; /* Remove right margin for every fourth card */
  }

  /* Filters are always visible on desktop */
  .filters-container {
    grid-area: filters;
    display: flex !important; /* Force display on desktop */
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 0 0 12px 12px;
  }

  .filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.75rem;
    width: 100%;
    max-width: 1200px;
  }

  .filters select,
  .filters input,
  .filters .admin-form-select {
    flex: 1;
    height: 40px;
    min-width: 150px;
    max-width: 200px;
    padding: 0 10px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 13px;
    text-overflow: ellipsis;
    background-color: white;
  }
  
  .filters .neighborhood-filter {
    flex: 2;
    min-width: 200px;
    max-width: 300px;
  }

  .filters .filter-buttons-container {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-left: auto;
  }

  .filters button,
  .filters .apply-filters-btn,
  .filters .clear-filters-btn,
  .filters .desktop-map-toggle {
    flex: 0 0 auto;
    height: 40px;
    padding: 0 12px;
    border: none;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
    width: auto;
    min-width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .filters .apply-filters-btn {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
    order: 1; /* First button */
  }
  
  .filters .clear-filters-btn {
    order: 3; /* Last button */
  }

  /* Hide toggle buttons on desktop */
  .filter-toggle-btn {
    display: none;
  }

  .mobile-language-toggle {
    display: none;
  }

  .desktop-language-toggle {
    display: block;
  }

  .mobile-post-button {
    display: none;
  }
}

/* Admin Panel Styles - Simplified Clean Implementation */
/* ------------------------------------------ */

/* Basic resets for admin interface */
.admin * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Main Layout Styles */
.admin {
  display: flex;
  min-height: 100vh;
  background-color: #f5f8fa;
  font-family: 'Roboto', sans-serif;
}

/* Sidebar Styles */
.admin-sidebar {
  width: 260px;
  background-color: #1e293b;
  color: #fff;
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 30;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.admin-sidebar-brand {
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-sidebar-brand h1 {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.admin-sidebar-brand svg {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

.admin-menu {
  list-style: none;
  padding: 16px 0;
}

.admin-menu-item {
  margin: 8px 16px;
}

.admin-menu-link {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.2s;
  font-weight: 500;
}

.admin-menu-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.admin-menu-link.active {
  background-color: rgba(99, 102, 241, 0.2);
  color: #fff;
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5) inset;
}

.admin-menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  flex-shrink: 0;
}

/* Content area */
.admin-content {
  flex: 1;
  margin-left: 260px;
  padding: 24px;
  width: calc(100% - 260px);
  transition: margin-left 0.3s ease, width 0.3s ease;
}

.admin-header {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-header h1 {
  font-size: 24px;
  font-weight: 700;
  color: #1e293b;
}

/* Mobile menu bar */
.admin-mobile-menu {
  display: none;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
  padding: 16px;
}

.admin-mobile-menu-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.admin-mobile-menu-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  padding: 12px 8px;
  border-radius: 8px;
  color: #64748b;
  transition: all 0.2s;
}

.admin-mobile-menu-link:hover {
  background-color: #f1f5f9;
}

.admin-mobile-menu-link.active {
  background-color: #f0f7ff;
  color: #4f46e5;
}

.admin-mobile-menu-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}

.admin-mobile-menu-text {
  font-size: 12px;
}

/* Card styles */
.admin-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 24px;
  margin-bottom: 16px;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100%;
  z-index: 1;
  position: relative;
}

.admin-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.admin-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.admin-card-title {
  font-size: 18px;
  font-weight: 600;
  color: #1e293b;
}

.listing-card .admin-card-title {
  font-size: 14px;
  margin: 6px 0 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 5px;
}

.admin-card-footer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e2e8f0;
}

/* Stats cards */
.admin-stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.admin-stat-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s, box-shadow 0.2s;
}

.admin-stat-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
}

.admin-stat-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.admin-stat-title {
  font-size: 14px;
  font-weight: 500;
  color: #64748b;
  margin-bottom: 8px;
}

.admin-stat-value {
  font-size: 28px;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 8px;
  line-height: 1.2;
}

.admin-stat-change {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.admin-stat-positive {
  color: #10b981;
}

.admin-stat-negative {
  color: #ef4444;
}

.admin-stat-neutral {
  color: #64748b;
}

.listing-card .admin-stat-neutral {
  font-size: 12px;
}

.admin-stat-icon {
  height: 42px;
  width: 42px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-stat-icon svg {
  width: 24px;
  height: 24px;
  color: white;
}

.admin-bg-blue {
  background-color: #3b82f6;
}

.admin-bg-green {
  background-color: #10b981;
}

.admin-bg-purple {
  background-color: #8b5cf6;
}

.admin-bg-amber {
  background-color: #f59e0b;
}

/* Chart cards */
.admin-charts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 24px;
}

.admin-chart-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 20px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  /* Add this to ensure that when screen aspect ratio changes, the chart adapts */
  position: relative;
  overflow: hidden;
  height: calc(100vw / 2.5); /* Dynamic height based on screen width */
  max-height: 450px;
}

/* Set dynamic heights based on viewing device */
@media (max-width: 1024px) {
  .admin-chart-card {
    height: calc(100vw / 2);
  }
}

@media (max-width: 768px) {
  .admin-chart-card {
    height: calc(100vw / 1.3);
    max-height: 400px;
  }
}

.admin-chart-title {
  font-size: 16px;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.admin-chart-title svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

/* Table styles */
.admin-table-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 24px;
}

.admin-table-header {
  padding: 16px 24px;
  background-color: white;
  border-bottom: 1px solid #f1f5f9;
}

.admin-table-title {
  font-size: 16px;
  font-weight: 600;
  color: #1e293b;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-table th {
  background-color: #f8fafc;
  text-align: left;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 600;
  color: #4b5563;
  border-bottom: 2px solid #e2e8f0;
}

.admin-table td {
  padding: 12px 16px;
  font-size: 14px;
  border-bottom: 1px solid #f1f5f9;
  color: #1e293b;
}

.admin-table tr:last-child td {
  border-bottom: none;
}

.admin-table tr:hover td {
  background-color: #f8fafc;
}

.admin-table-footer {
  padding: 16px 24px;
  background-color: #f8fafc;
  border-top: 1px solid #f1f5f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Button styles */
.admin-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
}

.admin-btn-primary {
  background-color: #4f46e5;
  color: white;
}

.admin-btn-primary:hover {
  background-color: #4338ca;
}

.admin-btn-secondary {
  background-color: white;
  color: #4b5563;
  border: 1px solid #e2e8f0;
}

.admin-btn-secondary:hover {
  background-color: #f8fafc;
}

.admin-btn-danger {
  background-color: #ef4444;
  color: white;
}

.admin-btn-danger:hover {
  background-color: #dc2626;
}

.admin-btn-icon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

/* Form styles */
.admin-form-group {
  margin-bottom: 16px;
}

.admin-form-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4b5563;
  margin-bottom: 8px;
}

.admin-form-input,
.admin-form-select,
.admin-form-textarea {
  width: 100%;
  padding: 10px 12px;
  font-size: 14px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  color: #1e293b;
  transition: all 0.2s;
}

.admin-form-input:focus,
.admin-form-select:focus,
.admin-form-textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.admin-form-textarea {
  min-height: 100px;
  resize: vertical;
}

/* Filter section */
.admin-filters {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 24px;
  position: relative;
  border-left: 4px solid #4f46e5;
}

.admin-filters-title {
  font-size: 16px;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 16px;
}

.admin-filters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
}

.admin-filters-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
  flex-wrap: nowrap;
}

/* Desktop version adjustments */
@media (min-width: 769px) {
  .admin-filters-actions {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
  }
  
  .admin-filters-actions button {
    flex: 0 0 auto;
    min-width: auto;
    height: 40px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
  }
  
  .admin-filters-actions button svg,
  .admin-filters-actions button .toggle-btn-icon {
    width: 16px !important;
    height: 16px !important;
    flex-shrink: 0;
    display: inline-block;
    margin-right: 6px;
  }
}

/* Status indicators */
.admin-status {
  display: inline-flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 500;
}

.admin-status-active {
  background-color: #dcfce7;
  color: #166534;
}

.admin-status-inactive {
  background-color: #fee2e2;
  color: #b91c1c;
}

.admin-status-pending {
  background-color: #fef3c7;
  color: #92400e;
}

/* Action buttons */
.admin-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.admin-btn-icon-only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.admin-btn-view {
  color: #3b82f6;
}

.admin-btn-view:hover {
  background-color: #eff6ff;
}

.admin-btn-edit {
  color: #10b981;
}

.admin-btn-edit:hover {
  background-color: #ecfdf5;
}

.admin-btn-delete {
  color: #ef4444;
}

.admin-btn-delete:hover {
  background-color: #fee2e2;
}

/* Modal styles */
.admin-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.admin-modal {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 550px;
  max-height: 90vh;
  overflow-y: auto;
}

.admin-modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid #f1f5f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 60px;
}

.admin-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #1e293b;
  width: calc(100% - 40px); /* Reserve space for the close button */
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.admin-modal-title .title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
}

.admin-modal-close {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #64748b;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  min-width: 32px;
  min-height: 32px;
  z-index: 1;
}

.admin-modal-close:hover {
  background-color: #f1f5f9;
  color: #1e293b;
}

.admin-modal-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.admin-modal-footer {
  padding: 16px 24px;
  border-top: 1px solid #f1f5f9;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Time period selector */
.admin-time-filter {
  display: inline-flex;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e2e8f0;
  margin-bottom: 24px;
}

.admin-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.admin-dashboard-title {
  font-size: 18px;
  font-weight: 600;
  color: #1e293b;
}

.admin-dashboard-filters {
  display: inline-flex;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e2e8f0;
}

.admin-tab-btn {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.admin-tab-btn:not(:last-child) {
  border-right: 1px solid #e2e8f0;
}

.admin-tab-btn.active {
  background-color: #4f46e5;
  color: white;
}

.admin-tab-btn:not(.active):hover {
  background-color: #f8fafc;
}

.admin-time-option {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.admin-time-option:not(:last-child) {
  border-right: 1px solid #e2e8f0;
}

.admin-time-option.active {
  background-color: #4f46e5;
  color: white;
}

.admin-time-option:not(.active):hover {
  background-color: #f8fafc;
}

.admin-chart-loading {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #64748b;
  background-color: #f8fafc;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .admin-chart-loading {
    height: 250px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .admin-chart-loading {
    height: 200px;
    font-size: 12px;
  }
}

/* MUI Table Styles Override */
.MuiTableContainer-root {
  border-radius: 12px !important;
  overflow: hidden !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
}

.MuiTableHead-root .MuiTableCell-head {
  background-color: #f8fafc !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #4b5563 !important;
  border-bottom: 2px solid #e2e8f0 !important;
  padding: 12px 16px !important;
}

.MuiTableBody-root .MuiTableCell-body {
  font-size: 14px !important;
  color: #1e293b !important;
  padding: 12px 16px !important;
  border-bottom: 1px solid #f1f5f9 !important;
}

.MuiTableRow-root:hover .MuiTableCell-body {
  background-color: #f8fafc !important;
}

.MuiTablePagination-root {
  color: #4b5563 !important;
}

.MuiTablePagination-selectRoot {
  margin-right: 12px !important;
}

.MuiTablePagination-actions {
  margin-left: 12px !important;
}

/* Responsive chart styles */
/* These styles help adjust chart display on different screen sizes */
.responsive-chart-container,
.chart-responsive-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
}

/* Handle aspect ratio changes and resizing */
.chart-responsive-container canvas {
  width: 100% !important;
  height: 100% !important;
  transition: 0.3s ease-in-out;
}

/* For admin chart cards, ensure they adapt to container size changes */
.admin-chart-card {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: auto;
  transition: height 0.3s ease;
  overflow: hidden;
}

/* Make chart containers fill available space */
.admin-chart-card .chart-responsive-container {
  flex: 1;
  transition: height 0.3s ease, width 0.3s ease;
}

/* Default chart point styles for desktop */
:root {
  --chart-point-radius: 5px;
  --chart-point-hover-radius: 7px;
  --chart-line-width: 2px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .admin-stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .admin-charts-grid {
    grid-template-columns: 1fr;
    transition: grid-template-columns 0.3s ease;
  }
  
  :root {
    --chart-point-radius: 4px;
    --chart-point-hover-radius: 6px;
  }
  
  /* Adjust the fullwidth chart */
  .admin-chart-card[style*="grid-column: 1 / -1"] {
    height: calc(100vw / 2.2);
  }
}

@media (max-width: 768px) {
  .admin-sidebar {
    transform: translateX(-100%);
  }
  
  .admin-sidebar.open {
    transform: translateX(0);
  }
  
  .admin-content {
    margin-left: 0;
    width: 100%;
  }
  
  .admin-mobile-menu {
    display: block;
  }
  
  .admin-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  
  .admin-stats-grid {
    grid-template-columns: 1fr;
  }
  
  .admin-filters-grid {
    grid-template-columns: 1fr;
  }
  
  /* Reduce chart point size on mobile */
  :root {
    --chart-point-radius: 3px;
    --chart-point-hover-radius: 4px;
    --chart-line-width: 1.5px;
  }
  
  /* Adjust chart labels and legends on mobile */
  .admin-chart-info {
    font-size: 11px !important;
    flex-direction: column;
  }
  
  .admin-chart-info p {
    margin-bottom: 5px;
  }
  
  /* Adjust the chart legend position for better mobile display */
  canvas {
    max-height: 300px;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  :root {
    --chart-point-radius: 2px;
    --chart-point-hover-radius: 3px;
    --chart-line-width: 1px;
  }
  
  .admin-chart-card {
    padding: 12px;
  }
  
  .admin-chart-title {
    font-size: 14px;
  }
  
  .admin-dashboard-filters {
    width: 100%;
  }
  
  .admin-tab-btn {
    flex: 1;
    padding: 6px 8px;
    font-size: 12px;
  }
}

